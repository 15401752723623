/* eslint-disable react/prop-types */
import { Tag } from 'antd';
import React, { useState } from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import { Link } from 'react-router-dom';
import { PRIVATE_ROUTE } from 'router';

const options = {
  disableDefaultUI: true,
};
function Map({ Projects, point, closeMarker, zoom }) {
  const [project, setSelected] = useState(null);
  //  console.log(point);
  if (!closeMarker)
    return (
      <GoogleMap defaultZoom={zoom} defaultCenter={point} options={options}>
        {Projects.map(marker => (
          <Marker
            // draggable
            key={marker.id}
            position={{ lat: marker.latitude, lng: marker.longitude }}
            icon={{
              url: marker.url,
              scaledSize: new window.google.maps.Size(50, 50),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
            }}
            //  onFocus={() => setproject(true)}
            onClick={() => setSelected(marker)}
          >
            {project && project.latitude === marker.latitude ? (
              <InfoWindow
                position={{ lat: project.latitude, lng: project.longitude }}
                onCloseClick={() => setSelected(null)}
              >
                <div>
                  <div>
                    <p>{project.name}</p>
                    {/* <p>
                      Status:
                      {project.status}
                    </p> */}
                  </div>
                  <br />
                  {project.organization && (
                    <>
                      <p>
                        Customer:
                        {project.organization}
                      </p>
                      <br />
                    </>
                  )}

                  {project?.active_projects?.length > 0 && (
                    <>
                      <p>Project:</p>
                      {project?.active_projects?.map(p => (
                        <Tag style={{ cursor: 'pointer' }} color="#faad14">
                          <Link
                            to={`/dashboard/${PRIVATE_ROUTE.PROJECTS}/${p.id}/`}
                            key={p.id}
                          >
                            {p.name}
                          </Link>
                        </Tag>
                      ))}
                    </>
                  )}
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </GoogleMap>
    );
  return <div>...</div>;
}
const LocationMap = withScriptjs(withGoogleMap(Map));
export default LocationMap;

/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Form, Input, Upload, Button, Row, Col, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import getValidateMessages, {
  getValidatedImageFile,
} from 'lib/helpers/validators';
import { getSingleOrganizationRequest } from 'features/OrgDetails/reducer';
// import { SelectCountryCode } from 'components/uielements';
import AddOrgFormWrapper from './EditOrgForm.styles';
import { updateOrgRequest } from '../reducer';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;

function EditOrgForm({ orgType = '' }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const intl = useIntl();
  const orgDetails = useSelector(state => state.OrgsDetails.orgDetails);

  useEffect(() => {
    dispatch(getSingleOrganizationRequest({ id }));
  }, [dispatch]);

  useEffect(() => {
    if (orgDetails) {
      console.log(orgDetails);
      const {
        name,
        org_number,
        org_email,
        address,
        postcode,
        city,
        send_sms,
        language,
        phone,
      } = orgDetails;
      form.setFieldsValue({
        name,
        org_number,
        org_email,
        address,
        postcode,
        city,
        send_sms: send_sms ? 'True' : 'False',
        language,
        phone,
      });
    }
  }, [form, orgDetails]);

  const normFile = ({ fileList }) => {
    // only get the last item
    const recentImage = fileList[fileList.length - 1].originFileObj;
    if (getValidatedImageFile(recentImage)) {
      return recentImage;
    }
    return null;
  };

  const onUpdateOrg = ({
    name,
    org_number,
    org_email,
    address,
    postcode,
    city,
    send_sms,
    language,
    phone,
    image,
  }) => {
    const data = {
      name,
      org_number,
      org_email,
      address,
      postcode,
      city,
      send_sms,
      language,
      phone,
      org_type: orgDetails.org_type,
      ...(image && { image }),
    };
    console.log(data);

    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));

    dispatch(
      updateOrgRequest({
        formData,
        resetForm: form.resetFields,
        succMessage:
          orgType === 'customer'
            ? intl.messages['Customer Successfully Updated!']
            : intl.messages['Supplier Successfully Updated!'],
        id,
      }),
    );
  };

  return (
    <AddOrgFormWrapper>
      <div className="org-details">
        <div className="form-header">
          <h2>
            {orgType === 'customer' ? (
              <FormattedMessage id="Edit Customer" />
            ) : (
              <FormattedMessage id="Edit Supplier" />
            )}
          </h2>
        </div>
        {orgDetails && (
          <Form
            name="add_new_org"
            onFinish={onUpdateOrg}
            labelCol={{ span: 24 }}
            form={form}
            validateMessages={getValidateMessages(intl.messages)}
          >
            <div className="customer_section">
              <Row gutter={50}>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Organization name']}
                    name="name"
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={intl.messages['Organization name']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Organization no']}
                    name="org_number"
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={`${intl.messages['Organization no']} (Ex: 559132-2671)`}
                      size="large"
                      type="number"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Organization email']}
                    name="org_email"
                    rules={[{ required: true, type: 'email' }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={intl.messages['Organization email']}
                      size="large"
                      type="email"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={intl.messages['Address']}
                name="address"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              >
                <TextArea
                  placeholder={intl.messages['Address']}
                  size="large"
                  rows={4}
                />
              </Form.Item>

              <Row gutter={50}>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Post code']}
                    name="postcode"
                    rules={[{ required: false }]}
                    hasFeedback
                  >
                    <Input
                      placeholder={intl.messages['Post code']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['City']}
                    name="city"
                    rules={[{ required: false }]}
                    hasFeedback
                  >
                    <Input placeholder={intl.messages['City']} size="large" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Phone']}
                    name="phone"
                    rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Input
                      // addonBefore={<SelectCountryCode name="country_code" />}
                      placeholder={intl.messages['Phone']}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={50}>
                <Col span={8}>
                  <Form.Item label={intl.messages['Sent SMS']} name="send_sms">
                    <Select
                      placeholder={intl.messages['Sent SMS Or Not']}
                      size="large"
                    >
                      <Option value="true">
                        <FormattedMessage id="True" />
                      </Option>
                      <Option value="false">
                        <FormattedMessage id="False" />
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={intl.messages['Language']}
                    name="language"
                    //   rules={[{ required: true }]}
                    hasFeedback
                  >
                    <Select
                      placeholder={intl.messages['Language']}
                      size="large"
                    >
                      <Option value="english">EN</Option>
                      <Option value="swedish">SV</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                getValueFromEvent={normFile}
                valuePropName="filelist"
                label={intl.messages['Add a logo/icon']}
                name="image"
                rules={[
                  {
                    required: false,
                  },
                ]}
                hasFeedback
              >
                <Dragger
                  // eslint-disable-next-line no-unused-vars
                  customRequest={({ _, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">Drag files here to upload</p>
                </Dragger>
              </Form.Item>

              <Row className="mt-2" type="flex" justify="center" gutter="70">
                <Col>
                  <Form.Item>
                    <Button onClick={() => history.goBack()} size="large">
                      <FormattedMessage id="Cancel" />
                    </Button>
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    <FormattedMessage id="Save" />
                  </Button>
                </Form.Item>
                <Col />
              </Row>
            </div>
          </Form>
        )}
      </div>
    </AddOrgFormWrapper>
  );
}

export default EditOrgForm;

import { takeLatest, put, call } from 'redux-saga/effects';
import { Reports } from 'api';
import {
  getObjectTrackingReq,
  getObjectTracking,
  getAllFieldList,
  getAllFieldListRequest,
  clearObjectTracking,
} from './reducer';

export function* getObjectTrackingSaga({
  payload: { locationId = '', from_date = '', to_date = '' },
}) {
  const res = yield call(
    Reports.getObjectTracking,
    locationId,
    from_date,
    to_date,
  );
  if (res.status === 200) {
    yield put(getObjectTracking(res.data));
  } else {
    yield put(clearObjectTracking());
  }
}

export function* getAllFieldListSaga({ payload: { pagination_off = false } }) {
  const res = yield call(Reports.getFieldList, pagination_off);
  if (res.status === 200) {
    yield put(getAllFieldList(res.data));
  }
}

export default function* watchrRportActions() {
  yield takeLatest(getObjectTrackingReq.toString(), getObjectTrackingSaga);
  yield takeLatest(getAllFieldListRequest.toString(), getAllFieldListSaga);
}

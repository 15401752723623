import { http } from 'services';
import { getHeader } from 'lib/helpers';
import {
  loginUrl,
  userRegUrl,
  usersUrl,
  userActivateUrl,
  resetPasswordUrl,
  changePasswordUrl,
  forgotPasswordUrl,
  countryCodeUrl,
} from './endpoints';

export const Users = {
  index: query =>
    http.get(query === 'gm' ? `${usersUrl}?role_set=${query}` : usersUrl, {
      headers: getHeader(),
    }),
  login: data => http.post(loginUrl, data),
  forgotPass: data => http.post(forgotPasswordUrl, data),
  register: data =>
    http.post(userRegUrl, data, {
      headers: getHeader(),
    }),
  updatePass: (id, data) =>
    http.post(`${changePasswordUrl}${id}/`, data, {
      headers: getHeader(),
    }),
  countryCode: () =>
    http.get(countryCodeUrl, {
      headers: getHeader(),
    }),
  acitvate: data => http.post(userActivateUrl, data),
  resetPass: data => http.post(resetPasswordUrl, data),
  updateUser: (id, data) =>
    http.post(`${usersUrl}update_by_admin/${id}/`, data, {
      headers: getHeader(),
    }),
  deleteUser: user_id =>
    http.post(
      `${usersUrl}delete_by_admin/${user_id}/`,
      {},
      {
        headers: getHeader(),
      },
    ),
  resendActivationEmail: id =>
    http.get(`${usersUrl}resend-activation/${id}/`, {
      headers: getHeader(),
    }),
};

import { http } from 'services';
import { getHeader } from 'lib/helpers';
import { locationMapUrl } from './endpoints';

export const LocationMap = {
  index: () =>
    http.get(`${locationMapUrl}`, {
      headers: getHeader(),
    }),
};

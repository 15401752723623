import React, { useEffect, useState } from 'react';
import { Card, Col, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import { getAllProjectsRequest } from 'features/Projects';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useIntl } from 'react-intl';
import LocationMap from './LocationMap';
import activePin from './assets/active pin.svg';
import completePin from './assets/completed pin.svg';
import delayedPin from './assets/delayed pin.svg';
// import active from './assets/active.svg';
// import completed from './assets/completed.svg';
// import delayed from './assets/delayed.svg';
import { getAllFieldLocationRequest } from './reducer';

// function getProgectList(results) {
//   let projects = [];
//   if (results && results.length > 0) {
//     projects = results.map(item => {
//       return {
//         latitude: item?.location?.point?.latitude,
//         longitude: item?.location?.point?.longitude,
//         name: item.name,
//         url:
//           // eslint-disable-next-line no-nested-ternary
//           item.status === 'completed'
//             ? completePin
//             : item.status === 'in-progress'
//             ? activePin
//             : delayedPin,
//         status: item.status,
//         id: item.id,
//         organization: item.organization.name,
//         project_type: item.project_type,
//       };
//     });
//   }
//   return projects;
// }
function getProgectList(results) {
  let projects = [];
  if (results && results.length > 0) {
    projects = results.map(item => {
      return {
        latitude: item?.point?.latitude,
        longitude: item?.point?.longitude,
        name: item.name,
        url:
          // eslint-disable-next-line no-nested-ternary
          item.status === 'completed'
            ? completePin
            : item.status === 'in-progress'
            ? activePin
            : delayedPin,
        // status: item.status,
        id: item.id,
        active_projects: item.active_projects,
        organization:
          item?.active_projects?.length > 0
            ? item?.active_projects[0].organization.name
            : undefined,
        // project_type: item.project_type,
      };
    });
  }
  return projects;
}

const Location = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [address, setAddress] = useState('');
  const [zoom, setZoom] = useState(4);
  const [point, setMapPoint] = useState({
    lat: 59.32932349999999,
    lng: 18.0685808,
  });
  const [closeMarker, setCloseMarker] = useState(false);
  // const { results } = useSelector(state => state.Projects.list);
  const { fields } = useSelector(state => state.FieldsLocations);
  console.log(fields);
  // useEffect(() => {
  //   dispatch(getAllProjectsRequest({ limit: 1000, offset: 0 }));
  // }, [dispatch]);

  useEffect(() => {
    // console.log('dispatch(getAllFieldLocation());');
    dispatch(getAllFieldLocationRequest());
  }, [dispatch]);

  // const projects = getProgectList(results);
  const projects = getProgectList(fields);
  const handleSelect = async value => {
    const result = await geocodeByAddress(value);
    const latLang = await getLatLng(result[0]);
    setCloseMarker(true);
    setAddress(value);
    setZoom(8);
    setMapPoint({
      lat: latLang.lat,
      lng: latLang.lng,
    });
    setCloseMarker(false);
  };
  return (
    <>
      <Card
        title="Location"
        // extra={
        //   // eslint-disable-next-line
        //   <div>
        //     <img style={{ margin: 3 }} src={active} alt="" />
        //     Active
        //     <img style={{ margin: 3 }} src={delayed} alt="" />
        //     Delayed
        //     <img style={{ margin: 3 }} src={completed} alt="" />
        //     Completed
        //   </div>
        // }
        // eslint-disable-next-line
      >
        <Row style={{ marginBottom: 10 }}>
          <Col span={8}>
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <Input
                    size="large"
                    {...getInputProps({
                      placeholder:
                        intl.messages[
                          'Search Location, longitude and latitude ...'
                        ],

                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
        </Row>
        <div style={{ width: '100%', height: '100vh' }}>
          <LocationMap
            point={point}
            Projects={projects}
            closeMarker={closeMarker}
            zoom={zoom}
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBYfmYnzkWO8iuc0jCrSJh1Gg7miz7EFh8"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        </div>
      </Card>
    </>
  );
};

export default Location;

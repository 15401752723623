import { combineReducers } from 'redux';
import { AuthSlice } from 'features/UserAuth';
import { CurrentUser } from 'features/UserSettings';
import { UsersSlice } from 'features/Users';
import { OrgsSlice } from 'features/Organizations';
import { OrgDetailsSlice } from 'features/OrgDetails';
import { ProjectSLice } from 'features/Projects';
import { EsignSlice } from 'features/Esign';
import { CalendarSlice } from 'features/Calender';
import { MessageSlice } from 'features/Message';
import StepSLice from 'features/Steps/reducer';
import { FieldSLice } from 'features/Fields';
import { TodoSLice } from 'features/Todo';
import { NotificationSLice } from 'features/Notifications';
import { ReportSLice } from 'features/Report';
import { LocationSlice } from 'features/Location';
import AppSlice from './app/reducer';
import langSwitchSlice from './languageSwitcher/reducer';

const allReducers = combineReducers({
  Auth: AuthSlice.reducer,
  App: AppSlice.reducer,
  LanguageSwitcher: langSwitchSlice.reducer,
  Users: UsersSlice.reducer,
  CurrentUser: CurrentUser.reducer,
  Orgs: OrgsSlice.reducer,
  OrgsDetails: OrgDetailsSlice.reducer,
  Projects: ProjectSLice.reducer,
  Esign: EsignSlice.reducer,
  Steps: StepSLice.reducer,
  Calendar: CalendarSlice.reducer,
  Message: MessageSlice.reducer,
  Fields: FieldSLice.reducer,
  Todo: TodoSLice.reducer,
  Notification: NotificationSLice.reducer,
  Reports: ReportSLice.reducer,
  FieldsLocations: LocationSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    // reset all reducers when the user is logged out
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;

import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = { objectTracking: {}, fieldlist: [] };

const ReportSLice = createSlice({
  name: 'report',
  initialState: initState,
  reducers: {
    getObjectTracking(state, { payload }) {
      state.objectTracking = payload;
    },
    clearObjectTracking(state) {
      state.objectTracking = {};
    },
    getAllFieldList(state, { payload }) {
      state.fieldlist = payload;
    },
  },
});

export const getObjectTrackingReq = createAction('get_object_tracking_request');
export const getAllFieldListRequest = createAction(
  'get_all_field_list_request',
);

export const {
  getObjectTracking,
  clearObjectTracking,
  getAllFieldList,
} = ReportSLice.actions;

export default ReportSLice;

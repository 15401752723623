import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button as AddUserButton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { UserModal } from 'features/Users';
import PropTypes from 'prop-types';
import { getAllUsersRequest, resendEmailRequest } from 'features/Users/reducer';
import { ShowForPermission } from 'components/utility';
import OrgUserCard from './OrgUserCard';

const OrgUsers = styled.div`
  max-height: 360px;
  overflow-y: scroll;
`;

const Button = styled(AddUserButton)`
  color: #3fb65f;
  margin: 20px 0 0;
`;

function OrgUserList({
  query,
  isUserListModalVisible,
  setIsUserListModalVisible,
  setIsModalVisible,
  userList,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsersRequest({ query }));
  }, [dispatch]);

  const handleCancel = () => {
    setIsUserListModalVisible(false);
  };

  const handleResendEmail = id => {
    dispatch(
      resendEmailRequest({
        id,
        succMessage: intl.messages['User Resend Email Successfully!'],
        query,
      }),
    );
  };
  return (
    <>
      <UserModal
        centered
        title={<FormattedMessage id="Users in this organization" />}
        visible={isUserListModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="91rem"
      >
        <OrgUsers>
          {userList.length > 0 ? (
            userList.map(user => (
              <OrgUserCard
                key={user.id}
                user={user}
                resendEmail={handleResendEmail}
              />
            ))
          ) : (
            <FormattedMessage id="No user found" />
          )}
        </OrgUsers>
        <ShowForPermission
          permission={[
            'can_add_new_supplier_user',
            'can_add_new_customer_user',
          ]}
        >
          <Button
            icon={<PlusOutlined style={{ marginRight: 10 }} />}
            type="text"
            onClick={() => setIsModalVisible(true)}
            className="font-bold"
          >
            <span>
              <FormattedMessage id="Add New" />
            </span>
          </Button>
        </ShowForPermission>
      </UserModal>
    </>
  );
}

OrgUserList.propTypes = {
  isUserListModalVisible: PropTypes.bool,
  query: PropTypes.number,
  setIsUserListModalVisible: PropTypes.func,
  setIsModalVisible: PropTypes.func,
  userList: PropTypes.shape([]),
};

export default OrgUserList;

import { all } from 'redux-saga/effects';
import LoginSagas from 'features/UserAuth/saga';
import { CurrentUserSaga } from 'features/UserSettings';
import { UsersSaga } from 'features/Users';
import { OrgsSaga } from 'features/Organizations';
import { OrgsDetailsSaga } from 'features/OrgDetails';
import { ProjectsSaga } from 'features/Projects';
import { EsignSaga } from 'features/Esign';
import { StepsSaga } from 'features/Steps';
import { CalendarSaga } from 'features/Calender';
import { MessageSaga } from 'features/Message';
import { FieldSaga } from 'features/Fields';
import { TodoSaga } from 'features/Todo';
import { NotificationSaga } from 'features/Notifications';
import { ReportSaga } from 'features/Report';
import { FieldLocationSaga } from 'features/Location';

export default function* rootSaga() {
  yield all([
    LoginSagas(),
    UsersSaga(),
    CurrentUserSaga(),
    OrgsSaga(),
    OrgsDetailsSaga(),
    ProjectsSaga(),
    EsignSaga(),
    StepsSaga(),
    CalendarSaga(),
    MessageSaga(),
    FieldSaga(),
    TodoSaga(),
    NotificationSaga(),
    ReportSaga(),
    FieldLocationSaga(),
  ]);
}

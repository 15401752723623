import { takeLatest, put, call } from 'redux-saga/effects';
import { LocationMap } from 'api';
import { getAllFieldLocation, getAllFieldLocationRequest } from './reducer';

export function* getAllFieldLocationSaga() {
  const res = yield call(LocationMap.index);
  if (res.data) {
    yield put(getAllFieldLocation(res.data));
  }
}

export default function* watchProjectsActions() {
  yield takeLatest(
    getAllFieldLocationRequest.toString(),
    getAllFieldLocationSaga,
  );
}

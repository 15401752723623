import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  list: [],
  maintenance: [],
  alarms: [],
  alarmsObjects: [],
  saimos_server: {},
  info: {},
};

const FieldSLice = createSlice({
  name: 'fields',
  initialState: initState,
  reducers: {
    getAllFields(state, { payload }) {
      state.list = payload;
    },
    updateFields(state, { payload }) {
      state.info = {
        ...state.info,
        ...payload,
      };
    },
    getFieldDetails(state, { payload }) {
      state.info = payload;
    },
    getAllMaintenance(state, { payload }) {
      state.maintenance = payload;
    },
    getAllAlarms(state, { payload }) {
      state.alarms = payload;
    },
    getAllAlarmsObjects(state, { payload }) {
      state.alarmsObjects = payload;
    },
    getSaimosServer(state, { payload }) {
      state.saimos_server = payload;
    },
  },
});

export const getAllFieldsRequest = createAction('get_all_fields_request');
export const getFieldDetailsRequest = createAction('get_field_details_request');
export const addNewFieldRequest = createAction('add_new_field_request');
export const updateFieldRequest = createAction('update_field_request');
export const createMaintenanceRequest = createAction(
  'create_maintenance_request',
);
export const getMaintenanceByLocationRequest = createAction(
  'get_maintenance_by_location_request',
);
export const updateMaintenanceRequest = createAction(
  'update_maintenance_request',
);
export const getAlarmsObjectsRequest = createAction(
  'get_alarms_objects_request',
);
export const createAlarmsRequest = createAction('create_alarms_request');
export const getAlarmsByLocationRequest = createAction(
  'get_alarms_by_location_request',
);
export const updateAlarmsRequest = createAction('update_alarms_request');
export const checkSaimosServerReq = createAction('check_saimos_server_request');
export const deletefieldsFileRequest = createAction(
  'delete_fields_file_request',
);

export const {
  getAllFields,
  updateFields,
  getFieldDetails,
  getAllMaintenance,
  getAllAlarmsObjects,
  getAllAlarms,
  getSaimosServer,
} = FieldSLice.actions;

export default FieldSLice;

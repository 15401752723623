import React, { useState } from 'react';
import Table from 'components/uielements/table';
import { Avatar, Divider, Popconfirm, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import userAvatar from 'assets/images/user-avatar.jpg';
import { EditOutlined, DeleteOutlined, SendOutlined } from '@ant-design/icons';
import UpdateUser from 'features/UpdateUser';
import { deleteUserRequest, resendEmailRequest } from './reducer';

function UserTable({ data }) {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [userInfo, setUserInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userPermissions = useSelector(
    state => state.CurrentUser.info.permissions,
  );
  const currentUserFromStore = useSelector(state => state.CurrentUser.info);
  const currentRole = currentUserFromStore.group.split('-')[0];

  // const currentOrgId = currentUserFromStore.organization
  //   ? currentUserFromStore.organization.id
  //   : '';
  const editUser = (visible, user) => {
    setUserInfo(user);
    setIsModalVisible(visible);
  };
  const deleteUser = userId => {
    dispatch(
      deleteUserRequest({
        user_id: userId,
        succMessage: intl.messages['User deleted successfully!'],
        query: currentRole,
      }),
    );
  };

  const checkPermission = text => {
    return userPermissions.includes(text);
  };

  const handleResendEmail = id => {
    dispatch(
      resendEmailRequest({
        id,
        succMessage: intl.messages['User Resend Email Successfully!'],
        query: currentRole,
      }),
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      width: 100,
      render: image => {
        return <Avatar src={image || userAvatar} />;
      },
    },
    {
      title: messages['Name'],
      dataIndex: 'name',
      width: 200,
      render: (name, record) => {
        return (
          <span>
            {`${record.first_name} `}
            {record.last_name}
          </span>
        );
      },
    },
    {
      title: messages['Email'],
      dataIndex: 'email',
      width: 200,
    },
    {
      title: messages['Phone'],
      dataIndex: 'phone',
      width: 200,
    },
    {
      title: messages['Role'],
      dataIndex: 'group',
      width: 200,
      render: text => messages[text],
    },
    {
      title: messages['Action'],
      dataIndex: 'action',
      width: 200,
      render: (action, record) => {
        return (
          <>
            <EditOutlined
              key="edit"
              onClick={() => {
                if (checkPermission('can_edit_customer_user')) {
                  editUser(true, record);
                }
              }}
            />
            <Divider type="vertical" />
            {currentUserFromStore?.id !== record.id ? (
              <Popconfirm
                title={messages['Delete.user.confirm']}
                onConfirm={() => {
                  deleteUser(record.id);
                }}
                okText="Yes"
                cancelText="No"
                disabled={!checkPermission('can_delete_user')}
              >
                <DeleteOutlined key="delete" />
              </Popconfirm>
            ) : null}
            <Divider type="vertical" />

            {!record?.is_active ? (
              <Tooltip
                key="resendEmail"
                placement="topLeft"
                title={intl.messages['Resend']}
                arrowPointAtCenter
              >
                <SendOutlined onClick={() => handleResendEmail(record.id)} />
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        //  onChange={getFilterData}
        // footer={() => (
        //   <div style={{ textAlign: 'center' }}>
        //     <Pagination
        //       defaultCurrent={offset}
        //       total={count}
        //       onChange={changePagination}
        //     />
        //   </div>
        // )}
      />
      <UpdateUser
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setUserInfo={setUserInfo}
        query={currentRole}
        userInfo={userInfo}
      />
    </div>
  );
}
UserTable.propTypes = {
  data: PropTypes.objectOf,
};
export default UserTable;

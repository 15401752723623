import { takeLatest, put, call, delay } from 'redux-saga/effects';
import { message } from 'antd';
import { Notification } from 'api';

import {
  getUnreadCount,
  getUnreadCountReq,
  getNotificationReq,
  getNotification,
  readNotificationReq,
  readAllNotificationReq,
  getUnreadNotification,
} from './reducer';

export function* getUnreadCountSaga() {
  const res = yield call(Notification.getUnreadCount);
  if (res?.data) {
    yield put(getUnreadCount(res.data?.count));
    yield delay(30000);
    yield call(getUnreadCountSaga);
  }
}

export function* getNotificationSaga({
  payload: { is_read, offset, limit, readCount = false },
}) {
  const res = yield call(Notification.getNotification, is_read, offset, limit);
  if (res?.data) {
    if (readCount) {
      yield put(getUnreadNotification(res.data));
      yield call(getUnreadCountSaga);
    } else {
      yield put(getNotification(res.data));
    }
  }
}

export function* readNotificationSaga({
  payload: { id, succMessage, offset, limit, is_read, readCount = false },
}) {
  const res = yield call(Notification.readNotification, id);
  if (res) {
    message.success(succMessage);
    yield call(getNotificationSaga, {
      payload: { is_read, offset, limit, readCount },
    });
  }
}

export function* readAllNotificationSaga({
  payload: { succMessage, offset = 0, limit },
}) {
  const res = yield call(Notification.readAllNotification);
  if (res) {
    message.success(succMessage);
    yield call(getNotificationSaga, {
      payload: { offset, limit },
    });
  }
}

export default function* watchNotificationActions() {
  yield takeLatest(getUnreadCountReq.toString(), getUnreadCountSaga);
  yield takeLatest(getNotificationReq.toString(), getNotificationSaga);
  yield takeLatest(readNotificationReq.toString(), readNotificationSaga);
  yield takeLatest(readAllNotificationReq.toString(), readAllNotificationSaga);
}

import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = {
  fields: [],
};

const LocationSlice = createSlice({
  name: 'locations',
  initialState: initState,
  reducers: {
    getAllFieldLocation(state, { payload }) {
      state.fields = payload;
    },
  },
});

export const getAllFieldLocationRequest = createAction(
  'locations/get_all_field_location_request',
);

export const { getAllFieldLocation } = LocationSlice.actions;

export default LocationSlice;

import React from 'react';
import { Row, Col, Card, Avatar } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// import { ShowForPermission } from 'components/utility';
import { InfoCardWrapper } from './styles';
import carImg from './assets/car.svg';
// import unknownImg from './assets/unknown.svg';
import peopleActivityImg from './assets/people-activity.svg';
import peopleImage from './assets/people.svg';
import settingsImage from './assets/settings.svg';

const { Meta } = Card;

const CountCards = () => {
  const intl = useIntl();
  const {
    total_people = 0,
    total_vehicles = 0,
    // total_unknown_object = 0,
    total_hours_people_activity = 0,
    total_maintenance_hours = 0,
  } = useSelector(
    state => state.Reports.objectTracking?.object_tracking_summary || {},
  );
  return (
    <InfoCardWrapper>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <Card>
            <Meta
              avatar={<Avatar src={peopleImage} />}
              title={`${total_people}`}
              description={intl.messages['People Visit']}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Meta
              avatar={<Avatar src={carImg} />}
              title={`${total_vehicles}`}
              description={intl.messages['Vehicles Visit']}
            />
          </Card>
        </Col>
        {/* <Col span={5}>
          <Card>
            <Meta
              avatar={<Avatar src={unknownImg} />}
              title={`${total_unknown_object}`}
              description={intl.messages['Unknown Visit']}
            />
          </Card>
        </Col> */}
        <Col span={6}>
          <Card>
            <Meta
              avatar={<Avatar src={peopleActivityImg} />}
              title={`${total_hours_people_activity} h`}
              description={intl.messages['Total People Activity']}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Meta
              avatar={<Avatar src={settingsImage} />}
              title={`${total_maintenance_hours} h`}
              description={intl.messages['Maintenance Hours']}
            />
          </Card>
        </Col>
      </Row>
    </InfoCardWrapper>
  );
};

export default CountCards;

import { createSlice, createAction } from '@reduxjs/toolkit';

const initState = { list: [], roles: [] };

const UsersSLice = createSlice({
  name: 'users',
  initialState: initState,
  reducers: {
    getAllUsers(state, { payload }) {
      state.list = payload;
    },
    getAllUserRoles(state, { payload }) {
      state.roles = payload;
    },
    getAllCountryCode(state, { payload }) {
      state.countryCode = payload;
    },
    updateUser(state, { payload }) {
      state.info = {
        ...state.info,
        ...payload,
      };
    },
  },
});

export const getAllUsersRequest = createAction('users/get_all_users_request');
export const deleteUserRequest = createAction('users/delete_users_request');
export const getAllUserRolesRequest = createAction(
  'users/get_all_user_roless_request',
);
export const addNewUserRequest = createAction('users/add_new_user_request');
export const activateUserRequest = createAction('users/activate_user_request');
export const resetPasswordRequest = createAction(
  'users/reset_password_request',
);
export const countryCodeRequest = createAction('users/country_code_request');
export const updateUserRequest = createAction('users/update_user_request');
export const resendEmailRequest = createAction('users/resend_email_request');

export const {
  getAllUsers,
  getAllUserRoles,
  getAllCountryCode,
} = UsersSLice.actions;

export default UsersSLice;

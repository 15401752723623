import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import ReportFilter from './ReportFilter';
import HeatMap from './HeatMap';

const BarCharts = React.lazy(() => import('./BarChart'));

function FieldReport({ locationId }) {
  return (
    <>
      <ReportFilter locationId={locationId || undefined} />
      <HeatMap />
      <Row gutter={16}>
        <Col span={14}>
          <React.Suspense fallback="">
            <BarCharts />
          </React.Suspense>
        </Col>
      </Row>
    </>
  );
}
FieldReport.propTypes = {
  locationId: PropTypes.number,
};

export default FieldReport;

import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { theme, AppLocale } from 'config';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/sv'; // import for swedish text calendar and date picker

export default function AppProvider({ children }) {
  let local = '';
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { info } = useSelector(state => state.CurrentUser);

  if (info && info.language) {
    local = info.language === 'swedish' ? 'sv' : 'en';
  } else {
    local = locale;
  }
  const currentAppLocale = AppLocale[local];
  moment.locale(local); // set language for calendar and date picker

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

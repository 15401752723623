import React from 'react';
import { FormattedMessage } from 'react-intl';
import FieldReport from './FieldReport';

function Report() {
  return (
    <>
      <h2 className="font-bold">
        <FormattedMessage id="Generate Field Report" />
      </h2>
      <FieldReport />
    </>
  );
}

export default Report;
